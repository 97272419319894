import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { TwitterPicker } from 'react-color';
import moment from 'moment';
import {
  Drawer,
  Input,
  InputNumber,
  Button,
  Image,
  Switch,
  Select,
  notification,
  Avatar,
  Tooltip,
  Upload
} from 'antd';
import { Comment } from '@ant-design/compatible';
import {
  InboxOutlined,
  CloseOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import Grocefy from '../../../../assets/images/grocefyLogoAlone.png';
import {
  selectedSectionAtom,
  reloadSectionsAtom
} from '../../../../atoms/Atoms';
import api from '../../../../api/api';

function EditSectionDrawer() {
  const { t } = useTranslation();
  const { Option } = Select;
  const { Dragger } = Upload;
  const setReloadSections = useSetRecoilState(reloadSectionsAtom);
  const [selectedSection, setSelectedSection] = useRecoilState(selectedSectionAtom);
  const [loading, setLoading] = useState(false);
  const [loadingDepartments, setLoadingDepartments] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [data, setData] = useState(null);
  const [imageData, setImageData] = useState(null);
  const draggerProps = {
    name: 'file',
    accept: 'image/*',
    multiple: false,
    showUploadList: false,
    beforeUpload: (file) => {
      setImageData(file);
      return false;
    }
  };

  function showMessage(message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  }

  async function uploadImage(imagePayload) {
    const boundary = Date.now();
    const response = await api
      .post('utility/productcampaign/uploadfile', imagePayload);
    setLoading(false);
    if (response.data.success) {
      const temp = JSON.parse(JSON.stringify(data));
      return response.data.data;
    }
    showMessage(response.data.error);
    return null;
  }

  function getDepartments() {
    setLoadingDepartments(true);
    api.get('businesses/supersections')
      .then((response) => {
        setLoadingDepartments(false);
        if (response.data.error) {
          showMessage(response.data.error);
        } else {
          setDepartments(response.data.data.results);
        }
      })
      .catch((err) => {
        setLoadingDepartments(false);
        showMessage(err.message);
      });
  }

  function submit() {
    setLoading(true);
    // console.log(JSON.stringify(data));
    api.post('businesses/menusections', data)
      .then((response) => {
        setLoading(false);
        if (response.data.error) {
          showMessage(response.data.error);
        } else {
          setReloadSections(true);
          setSelectedSection(null);
        }
      })
      .catch((err) => {
        setLoading(false);
        showMessage(err.message);
      });
  }

  useEffect(async () => {
    if (imageData) {
      const file = imageData;
      const imagePayload = new FormData();
      imagePayload.append('file', file);
      setLoading(true);
      const imageUrl = await uploadImage(imagePayload);
      setImageData(null);
      if (imageUrl) {
        setData({
          ...data,
          imageUrl
        });
      }
    }
  }, [imageData]);

  useEffect(() => {
    if (selectedSection) {
      const temp = { ...selectedSection };
      if (!temp.isAlcoholicBeverage) {
        temp.isAlcoholicBeverage = false;
      }
      if (!temp.color) {
        temp.color = '#ffffff';
        temp.borderColor = '#ffffff';
      }
      if (!temp.ordering) {
        temp.ordering = 99;
      }
      setData(temp);
      getDepartments();
    }
  }, [selectedSection]);

  useEffect(() => {
    if (!data?.superSectionId && departments) {
      setData({
        ...data,
        superSectionId: departments[0]?.id
      });
    }
  }, [departments]);

  return (
    <Drawer
      title={selectedSection?.id ? t('edit_section') : t('create_section')}
      placement="right"
      closable={!loading}
      onClose={() => setSelectedSection(null)}
      open={selectedSection}
      width={400}
    >
      <div className="mb-4">
        <strong>{`${t('name')} (ES)`}</strong>
        <Input
          disabled={loading}
          onChange={(obj) => {
            setData({
              ...data,
              name: obj.target.value
            });
          }}
          value={data?.name}
        />
      </div>
      <div className="mb-4">
        <strong>{`${t('name')} (EN)`}</strong>
        <Input
          disabled={loading}
          onChange={(obj) => {
            setData({
              ...data,
              nameEng: obj.target.value
            });
          }}
          value={data?.nameEng}
        />
      </div>
      <div className="mb-4">
        <strong>{t('department')}: </strong>
        <br />
        <Select
          defaultValue={data?.superSectionId}
          value={data?.superSectionId}
          className="w-full"
          disabled={loading || loadingDepartments}
          onChange={(obj) => {
            setData({
              ...data,
              superSectionId: obj
            });
          }}
        >
          {_.map(departments, (d) => (
            <Option value={d.id}>{`${d.name}/${d.nameEng}`}</Option>
          ))}
        </Select>
      </div>
      <div className="mb-4">
        <strong>{t('aisle')}</strong>
        <Input
          disabled={loading}
          onChange={(obj) => {
            setData({
              ...data,
              aisle: obj.target.value
            });
          }}
          value={data?.aisle}
        />
      </div>
      <div className="mb-4">
        <strong>{t('is_alcohol')} </strong>
        <Switch
          checked={data?.isAlcoholicBeverage}
          disabled={loading}
          onChange={(checked) => {
            setData({
              ...data,
              isAlcoholicBeverage: checked
            });
          }}
        />
      </div>
      <div className="mb-4">
        <strong>{t('ordering')} </strong>
        <InputNumber
          disabled={loading}
          onChange={(obj) => {
            setData({
              ...data,
              ordering: obj
            });
          }}
          value={data?.ordering}
        />
      </div>
      <div className="mb-4">
        <strong>{t('color')}</strong>
        <Input
          onChange={(obj) => {
            setData({
              ...data,
              color: obj?.target?.value
            });
          }}
          value={data?.color}
        />
        <TwitterPicker
          color={data?.color}
          onChangeComplete={(obj) => {
            let hex = '';
            if (obj?.hex) {
              hex = obj?.hex;
            } else {
              hex = obj?.target?.value;
            }
            setData({
              ...data,
              color: hex
            });
          }}
        />
      </div>
      <div className="mb-4">
        <strong>{t('border_color')}</strong>
        <Input
          onChange={(obj) => {
            setData({
              ...data,
              borderColor: obj?.target?.value
            });
          }}
          value={data?.borderColor}
        />
        <TwitterPicker
          color={data?.borderColor}
          onChangeComplete={(obj) => {
            let hex = '';
            if (obj?.hex) {
              hex = obj?.hex;
            } else {
              hex = obj?.target?.value;
            }
            setData({
              ...data,
              borderColor: hex
            });
          }}
        />
      </div>
      <div className="mb-4">
        <Tooltip title={t('image_requirements')}>
          <strong>{t('image')}:</strong>
        </Tooltip>
        <Input
          disabled={loading}
          onChange={(obj) => {
            setData({
              ...data,
              imageUrl: obj.target.value
            });
          }}
          value={data?.imageUrl}
        />
        <Dragger {...draggerProps}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p
            className="ant-upload-text"
            style={{ margin: 10 }}
          >
            {t('click_or_drag_content')}
          </p>
        </Dragger>
        {data?.imageUrl?.length > 0 && (
          <>
            <Image src={data?.imageUrl} height={75} />
            <br />
            <Button
              disabled={loading}
              type="primary"
              danger
              onClick={() => {
                setData({
                  ...data,
                  imageUrl: ''
                });
              }}
            >
              <CloseOutlined />
            </Button>
          </>
        )}
      </div>
      <div className="mb-4 float-right">
        <Button
          disabled={!data?.name?.length > 0 ||
            !data?.nameEng?.length > 0 ||
            !data?.superSectionId.length > 0}
          loading={loading}
          type="primary"
          onClick={() => { submit(); }}
        >
          {data?.id ? t('update') : t('create')}
        </Button>
      </div>
    </Drawer>
  );
}

export default EditSectionDrawer;
