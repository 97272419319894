import React, { useEffect, useState } from 'react';
import {
  useRecoilState,
  useRecoilValueLoadable,
  useSetRecoilState
} from 'recoil';
import {
  Image,
  Button,
  notification,
  Popconfirm,
  Avatar,
  Tooltip,
  Table
} from 'antd';
import { Comment } from '@ant-design/compatible';
import { PageHeader } from '@ant-design/pro-layout';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Grocefy from '../../../assets/images/grocefyLogoAlone.png';
import {
  profileAtom,
  selectedDepartmentAtom,
  reloadDepartmentsAtom,
  selectedDepartmentOrSectionForLogsAtom,
  showDepartmentsSectionsModalAtom
} from '../../../atoms/Atoms';
import api from '../../../api/api';
import EditDepartmentDrawer from './shared-components/EditDepartmentDrawer';
import LogsModal from './shared-components/LogsModal';
import DepartmentCategoriesBreakdownModal from './shared-components/DepartmentCategoriesBreakdownModal';

function Departments() {
  const { t } = useTranslation();
  const history = useHistory();
  const userProfile = useRecoilValueLoadable(profileAtom);
  const isAdmin =
    userProfile.contents && userProfile?.contents?.roles?.includes('SuperAdmin');
  const isCustomerService =
    isAdmin || (userProfile?.contents && (userProfile?.contents?.roles?.includes('CustomerServiceAdmin') || userProfile?.contents?.roles?.includes('CustomerService')));
  const isStoreManager =
    isAdmin || isCustomerService || (userProfile?.contents && (userProfile?.contents?.roles?.includes('StoreManager') || userProfile?.contents?.roles?.includes('StoreAdmin')));
  if (!isAdmin && !isCustomerService && !isStoreManager) {
    history.push('/');
    return null;
  }

  const setSelectedDepartment = useSetRecoilState(selectedDepartmentAtom);
  const [reloadDepartments, setReloadDepartments] = useRecoilState(reloadDepartmentsAtom);
  const [departments, setDepartments] = useState([]);
  const [loading, setLoading] = useState(false);
  const setSelectedDepartmentOrSectionForLogsAtom = useSetRecoilState(selectedDepartmentOrSectionForLogsAtom);
  const setShowDepartmentsSectionsModal = useSetRecoilState(showDepartmentsSectionsModalAtom);

  function showMessage(message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  }

  function setLoadingInTable(id, load) {
    const temp = JSON.parse(JSON.stringify(departments));
    for (let i = 0; i < temp.length; i++) {
      if (temp[i].id === id) {
        temp[i].loading = load;
      }
    }
    setDepartments(temp);
  }

  function getDepartments() {
    setLoading(true);
    api.get('businesses/supersections')
      .then((response) => {
        setLoading(false);
        if (response.data.error) {
          showMessage(response.data.error);
        } else {
          setDepartments(response.data.data.results);
        }
      })
      .catch((err) => {
        setLoading(false);
        showMessage(err.message);
      });
  }

  function remove(id) {
    setLoadingInTable(id, true);
    api.delete(`businesses/departments/${id}`)
      .then((response) => {
        setLoading(false);
        if (response.data.error) {
          showMessage(response.data.error);
        } else {
          getDepartments();
        }
      })
      .catch((err) => {
        setLoadingInTable(id, false);
        showMessage(err.message);
      });
  }

  useEffect(() => {
    getDepartments();
    setSelectedDepartment(null);
  }, []);

  useEffect(() => {
    if (reloadDepartments) {
      getDepartments();
      setReloadDepartments(false);
    }
  }, [reloadDepartments]);

  return (
    <div>
      <PageHeader
        title={t('departments')}
        extra={[
          <Button
            key="viewdeptcatdetails"
            type="primary"
            size="small"
            onClick={() => {
              setShowDepartmentsSectionsModal(true);
            }}
          >
            {t('view_departments_categories')}
          </Button>,
          <Button
            key="create"
            type="primary"
            size="small"
            onClick={() => {
              setSelectedDepartment({
                color: '#ffffff',
                borderColor: '#ffffff'
              });
            }}
          >
            {t('create')}
          </Button>,
          <Button
            key="reload"
            type="primary"
            size="small"
            onClick={() => {
              setReloadDepartments(true);
            }}
          >
            {t('reload')}
          </Button>
        ]}
      />
      <Table
        size="small"
        bordered
        scroll={{ x: 500 }}
        loading={loading}
        pagination={false}
        rowKey="id"
        dataSource={departments}
        columns={[
          {
            title: t('image'),
            dataIndex: 'imageUrl',
            align: 'center',
            className: 'text-xs',
            render: (text) => (
              <Image src={text} width={75} />
            ),
          },
          {
            title: `${t('name')} (ES)`,
            dataIndex: 'name',
            align: 'center',
            className: 'text-xs',
            render: (text) => (
              <span>{text}</span>
            ),
          },
          {
            title: `${t('name')} (EN)`,
            dataIndex: 'nameEng',
            align: 'center',
            className: 'text-xs',
            render: (text) => (
              <span>{text}</span>
            ),
          },
          // {
          //   title: `${t('grouping_name')} (ES)`,
          //   dataIndex: 'groupingName',
          //   align: 'center',
          //   className: 'text-xs',
          //   render: (text) => (
          //     <span>{text}</span>
          //   ),
          // },
          // {
          //   title: `${t('grouping_name')} (EN)`,
          //   dataIndex: 'groupingNameEng',
          //   align: 'center',
          //   className: 'text-xs',
          //   render: (text) => (
          //     <span>{text}</span>
          //   ),
          // },
          {
            title: '',
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <div className="space-x-2">
                <Button
                  size="small"
                  type="primary"
                  onClick={() => {
                    setSelectedDepartment(row);
                  }}
                >
                  {t('edit')}
                </Button>
                <Popconfirm
                  title={t('delete_section_prompt')}
                  onConfirm={() => remove(row.id)}
                  okText={t('yes')}
                  cancelText={t('no')}
                >
                  <Button
                    loading={row.loading}
                    disabled={row.loading}
                    size="small"
                    type="primary"
                    danger
                  >
                    {t('remove')}
                  </Button>
                </Popconfirm>
                {isAdmin && (
                  <Button
                    size="small"
                    type="primary"
                    onClick={() => {
                      setSelectedDepartmentOrSectionForLogsAtom({ departmentId: row.id });
                    }}
                  >
                    {t('logs')}
                  </Button>
                )}
              </div>
            ),
          }
        ]}
      />
      <EditDepartmentDrawer />
      <LogsModal />
      <DepartmentCategoriesBreakdownModal />
    </div>
  );
}

export default Departments;
