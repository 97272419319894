import React, { useEffect, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { TwitterPicker } from 'react-color';
import moment from 'moment';
import {
  Drawer,
  Input,
  Button,
  Image,
  InputNumber,
  notification,
  Avatar,
  Tooltip,
  Upload
} from 'antd';
import { Comment } from '@ant-design/compatible';
import {
  InboxOutlined,
  CloseOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import Grocefy from '../../../../assets/images/grocefyLogoAlone.png';
import {
  selectedDepartmentAtom,
  reloadDepartmentsAtom
} from '../../../../atoms/Atoms';
import api from '../../../../api/api';

function EditDepartmentDrawer() {
  const { t } = useTranslation();
  const { Dragger } = Upload;
  const setReloadDepartments = useSetRecoilState(reloadDepartmentsAtom);
  const [selectedDepartment, setSelectedDepartment] = useRecoilState(selectedDepartmentAtom);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [imageData, setImageData] = useState(null);
  const draggerProps = {
    name: 'file',
    accept: 'image/*',
    multiple: false,
    showUploadList: false,
    beforeUpload: (file) => {
      setImageData(file);
      return false;
    }
  };

  function showMessage(message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  }

  async function uploadImage(imagePayload) {
    const boundary = Date.now();
    const response = await api
      .post('utility/productcampaign/uploadfile', imagePayload);
    setLoading(false);
    if (response.data.success) {
      const temp = JSON.parse(JSON.stringify(data));
      return response.data.data;
    }
    showMessage(response.data.error);
    return null;
  }

  function submit() {
    setLoading(true);
    if (data.id) {
      api.put('businesses/supersection', data)
        .then((response) => {
          setLoading(false);
          if (response.data.error) {
            showMessage(response.data.error);
          } else {
            setReloadDepartments(true);
            setSelectedDepartment(null);
          }
        })
        .catch((err) => {
          setLoading(false);
          showMessage(err.message);
        });
    } else {
      api.post('businesses/supersection', data)
        .then((response) => {
          setLoading(false);
          if (response.data.error) {
            showMessage(response.data.error);
          } else {
            setReloadDepartments(true);
            setSelectedDepartment(null);
          }
        })
        .catch((err) => {
          setLoading(false);
          showMessage(err.message);
        });
    }
  }

  useEffect(async () => {
    if (imageData) {
      const file = imageData;
      const imagePayload = new FormData();
      imagePayload.append('file', file);
      setLoading(true);
      const imageUrl = await uploadImage(imagePayload);
      setImageData(null);
      if (imageUrl) {
        setData({
          ...data,
          imageUrl
        });
      }
    }
  }, [imageData]);

  useEffect(() => {
    if (selectedDepartment) {
      const temp = { ...selectedDepartment };
      if (!temp.color) {
        temp.color = '#ffffff';
        temp.borderColor = '#ffffff';
      }
      if (!temp.ordering) {
        temp.ordering = 99;
      }
      setData(temp);
    }
  }, [selectedDepartment]);

  return (
    <Drawer
      title={selectedDepartment?.id ? t('edit_department') : t('create_department')}
      placement="right"
      closable={!loading}
      onClose={() => setSelectedDepartment(null)}
      open={selectedDepartment}
      width={400}
    >
      <div className="mb-4">
        <strong>{`${t('name')} (ES)`}</strong>
        <Input
          disabled={loading}
          onChange={(obj) => {
            setData({
              ...data,
              name: obj.target.value
            });
          }}
          value={data?.name}
        />
      </div>
      <div className="mb-4">
        <strong>{`${t('name')} (EN)`}</strong>
        <Input
          disabled={loading}
          onChange={(obj) => {
            setData({
              ...data,
              nameEng: obj.target.value
            });
          }}
          value={data?.nameEng}
        />
      </div>
      <div className="mb-4">
        <strong>{t('ordering')}</strong>
        <InputNumber
          disabled={loading}
          onChange={(obj) => {
            setData({
              ...data,
              ordering: obj
            });
          }}
          value={data?.ordering}
        />
      </div>
      <div className="mb-4">
        <strong>{t('color')}</strong>
        <Input
          onChange={(obj) => {
            setData({
              ...data,
              color: obj?.target?.value
            });
          }}
          value={data?.color}
        />
        <TwitterPicker
          color={data?.color}
          onChangeComplete={(obj) => {
            let hex = '';
            if (obj?.hex) {
              hex = obj?.hex;
            } else {
              hex = obj?.target?.value;
            }
            setData({
              ...data,
              color: hex
            });
          }}
        />
      </div>
      <div className="mb-4">
        <strong>{t('border_color')}</strong>
        <Input
          onChange={(obj) => {
            setData({
              ...data,
              borderColor: obj?.target?.value
            });
          }}
          value={data?.borderColor}
        />
        <TwitterPicker
          color={data?.borderColor}
          onChangeComplete={(obj) => {
            let hex = '';
            if (obj?.hex) {
              hex = obj?.hex;
            } else {
              hex = obj?.target?.value;
            }
            setData({
              ...data,
              borderColor: hex
            });
          }}
        />
      </div>
      <div className="mb-4">
        <Tooltip title={t('image_requirements')}>
          <strong>{t('image')}:</strong>
        </Tooltip>
        <Input
          disabled={loading}
          onChange={(obj) => {
            setData({
              ...data,
              imageUrl: obj.target.value
            });
          }}
          value={data?.imageUrl}
        />
        <Dragger {...draggerProps}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p
            className="ant-upload-text"
            style={{ margin: 10 }}
          >
            {t('click_or_drag_content')}
          </p>
        </Dragger>
        {data?.imageUrl?.length > 0 && (
          <>
            <Image src={data?.imageUrl} height={75} />
            <br />
            <Button
              disabled={loading}
              type="primary"
              danger
              onClick={() => {
                setData({
                  ...data,
                  imageUrl: ''
                });
              }}
            >
              <CloseOutlined />
            </Button>
          </>
        )}
      </div>
      <div className="mb-4 float-right">
        <Button
          disabled={!data?.name?.length > 0 ||
            !data?.nameEng?.length > 0}
          loading={loading}
          type="primary"
          onClick={() => { submit(); }}
        >
          {data?.id ? t('update') : t('create')}
        </Button>
      </div>
    </Drawer>
  );
}

export default EditDepartmentDrawer;
