import { useMount } from 'react-use';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import is from 'is_js';
import dayjs from 'dayjs';
import {
  Drawer,
  Tabs,
  Typography,
  notification,
  Divider,
  Row,
  Col,
  Tooltip,
  DatePicker,
} from 'antd';
import {
  QuestionCircleOutlined
} from '@ant-design/icons';
import { Comment } from '@ant-design/compatible';
import Avatar from 'antd/lib/avatar/avatar';
import {
  useRecoilState,
  useRecoilValue,
  useRecoilValueLoadable,
  useSetRecoilState,
} from 'recoil';
import moment from 'moment';
import _ from 'lodash';
import {
  businessAtom,
  productEditAtom,
  reloadProduct,
  searchTermAtom,
  tokenAtom,
} from '../../../atoms/Atoms';
import '../../../css/checkbox.css';
import Grocefy from '../../../assets/images/grocefyLogoAlone.png';
import { productCategoriesSelector } from '../../../atoms/Selectors';
import envConfig from '../../../envConfig';
import api from '../../../api/api';

function EditProduct() {
  const { t, i18n } = useTranslation();
  const [product, setProduct] = useRecoilState(productEditAtom);
  const [categories, setCategories] = useState(null);
  const [loading, setLoading] = useState(null);
  const token = useRecoilValue(tokenAtom);
  const setSearchTermAtom = useSetRecoilState(searchTermAtom);
  const store = useRecoilValue(businessAtom);
  // const categories = useRecoilValueLoadable(productCategoriesSelector);
  const [cats, setCats] = useState(null);
  const [reload, setReload] = useRecoilState(reloadProduct);
  const [dates, setDates] = useState(null);
  const { RangePicker } = DatePicker;

  useEffect(() => {
    if (product) {
      const temp = JSON.parse(JSON.stringify(product));
      if (!temp.promoStartDate || temp.promoStartDate.length === 0) {
        temp.promoStartDate = moment();
      }
      if (!temp.promoEndDate || temp.promoEndDate.length === 0) {
        temp.promoEndDate = moment();
      }
      setDates(temp);
    }
  }, [product]);

  // useEffect(() => {
  //   if (dates) {
  //     const temp = JSON.parse(JSON.stringify(dates));
  //     if (temp.promoStartDate && temp.promoStartDate.length > 0) {
  //       temp.promoStartDate = moment(temp.promoStartDate);
  //     }
  //     if (temp.promoStartDate && temp.promoStartDate.length > 0 && temp.promoStartDate.length < 17) {
  //       temp.promoStartDate = `${temp.promoStartDate.replace(' ', 'T')}:00.000`;
  //     }
  //   }
  // }, [dates?.promoStartDate]);

  // useEffect(() => {
  //   if (dates) {
  //     const temp = JSON.parse(JSON.stringify(dates));
  //     if (temp.promoEndDate && temp.promoEndDate.length > 0) {
  //       temp.promoEndDate = moment(temp.promoEndDate);
  //     }
  //     if (temp.promoEndDate && temp.promoEndDate.length > 0 && temp.promoEndDate.length < 17) {
  //       temp.promoEndDate = `${temp.promoEndDate.replace(' ', 'T')}:00.000`;
  //     }
  //   }
  // }, [dates?.promoEndDate]);

  const showMessage = function (message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  };
  function mapSections(data) {
    const mapped = _.map(data, (p, index) => ({
      key: index,
      id: p.id,
      name: p.name,
      nameEng: p.nameEng,
      superSectionName: p.superSectionName,
      superSectionNameEng: p.superSectionNameEng
    }));

    return _.sortBy(mapped, [(o) => o.name]);
  }

  const getSections = function () {
    api
      .get(`businesses/${store?.id}/menusections`)
      .then((response) => {
        if (response.data.success) {
          setCategories(mapSections(response.data.data.results));
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        showMessage(error);
      });
  };
  function mapData(data) {
    const cat = _.map(data, (p) => ({
      key: p.id,
      name: p.name,
      description: p.description,
    }));
    setCats(cat);
  }

  // useMount(() => {
  //   if (!store) return;
  //   if (store.id !== -2 && store.id !== -1) {
  //     api
  //       .get(`businesses/${store.id}/menusections/filtered`)
  //       .then((response) => {
  //         mapData(response.data.data.results);
  //       })
  //       .catch((error) => {
  //         console.error(error);
  //       });
  //   }
  // });

  useMount(() => {
    getSections();
  });

  useEffect(() => {
    getSections();
  }, [store]);

  function prepareForm() {
    if (product == null) {
      return <div>No Data Loaded</div>;
    }

    return (
      <div>
        <Formik
          initialValues={{
            name: product.name,
            brand: product.brand,
            description: product.description,
            aisle: product.aisle,
            available: product.available,
            visible: product.visible,
            minQuantity: product.minQuantity,
            maxQuantity: product.maxQuantity,
            sectionId: product.sectionId,
            type: product.type,
            department: product.department
          }}
          validate={(values) => {
            const errors = {};
            if (is.empty(values.name)) {
              errors.name = 'Name is a required field.';
            }
            if (values.minQuantity < 0) {
              errors.minQuantity = 'Minimum cant be a negative number.';
            }
            if (values.maxQuantity < 0) {
              errors.maxQuantity = 'Maximum cant be a negative number.';
            }

            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            api
              .post(
                `businesses/catalog/business/${product.key}/update`,
                {
                  Aisle: values.aisle ? values.aisle : product.aisle,
                  ImageUrl: product.imageUrl,
                  IsAvailable: values.available,
                  IsVisible: values.visible,
                  SectionId: values.sectionId,
                  Department: values.department,
                  Type: values.type,
                  Data: {
                    MinQuantity: values.minQuantity,
                    MaxQuantity: values.maxQuantity,
                  },
                },
                {
                  headers: {
                    'Ocp-Apim-Subscription-Key':
                      envConfig.REACT_APP_API_TOKEN,
                    Authorization: `Bearer ${token}`,
                  },
                }
              )
              .then((response) => {
                if (response && response.data.error) {
                  notification.open({
                    message: 'Alert!',
                    description: (
                      <Comment
                        author={<span>Grocefy</span>}
                        avatar={<Avatar src={Grocefy} alt="grocefy" />}
                        content={
                          <p className="text-sm">{response.data.error}</p>
                        }
                      />
                    ),
                  });
                } else if (
                  response &&
                  response.data.success
                ) {
                  notification.open({
                    message: 'Success!',
                    description: (
                      <Comment
                        author={<span>Grocefy</span>}
                        avatar={<Avatar src={Grocefy} alt="grocefy" />}
                        content={
                          <p className="text-sm">Success!</p>
                        }
                      />
                    ),
                  });
                }
                setSubmitting(false);
                setProduct(null);
                setSearchTermAtom(product.upc);
                setReload(!reload);
              })
              .catch((error) => {
                console.error(error);
                setSubmitting(false);
              });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <div className="my-4">
                <div className="font-semibold text-md tracking-tight mb-1">
                  <span>{t('product_group')}</span>
                  <Tooltip title={t('product_group_helper')}>
                    <QuestionCircleOutlined className="text-xs ml-1 text-green-600" />
                  </Tooltip>
                </div>
                <input
                  type="text"
                  name="department"
                  id="department"
                  className="text-gray-900 text-sm rounded p-2 w-full appearance-none ring-0 focus:ring-0 border border-gray-200 focus:border-gray-300 outline-none focus:outline-none transition ease-in-out duration-500"
                  value={values.department}
                  onChange={handleChange}
                />
                {errors.department && touched.department && (
                  <div className="w-full font-semibold text-xs text-red-400 mt-1">
                    {errors.department}
                  </div>
                )}
              </div>
              <div className="my-4">
                <div className="font-semibold text-md tracking-tight mb-1">
                  {t('aisle_with_desc')}
                </div>
                <input
                  type="text"
                  name="aisle"
                  id="aisle"
                  placeholder="i.e. Aisle 4 or Meat Freezer"
                  className="text-gray-900 text-sm rounded p-2 w-full appearance-none ring-0 focus:ring-0 border border-gray-200 focus:border-gray-300 outline-none focus:outline-none transition ease-in-out duration-500"
                  value={values.aisle}
                  onChange={handleChange}
                />
                {errors.aisle && touched.aisle && (
                  <div className="w-full font-semibold text-xs text-red-400 mt-1">
                    {errors.aisle}
                  </div>
                )}
              </div>
              <div className="my-4">
                <div className="font-semibold font-md tracking-tight mb-1">
                  {t('category')}
                </div>
                {categories && (
                  <select
                    id="sectionId"
                    name="sectionId"
                    value={values.sectionId}
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                      if (option && option.children) {
                        return option.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                          option.children[2].toLowerCase().indexOf(input.toLowerCase()) >= 0;
                      }
                      return false;
                    }}
                    onChange={handleChange}
                  >
                    {_.map(categories, (b) => (
                      <option
                        key={b.id}
                        value={b.id}
                        className="text-sm flex items-center"
                      >
                        {b.name} @ {b.superSectionName}
                      </option>
                    ))}
                  </select>
                )}
                {errors.sectionId && touched.sectionId && (
                  <div className="w-full font-semibold text-xs text-red-400 mt-1">
                    {errors.sectionId}
                  </div>
                )}
              </div>
              <br />
              <div className="font-semibold font-md tracking-tight mb-2">
                {t('e-commerce_visibility')}
              </div>
              <div className="mb-2 flex flex-column">
                <input
                  id="available"
                  type="checkbox"
                  name="available"
                  className="switch"
                  checked={values.available}
                  onChange={handleChange}
                />
                <Typography className="text-base">
                  {' '}
                  {`${
                    values.available
                      ? t('available_for_purchase')
                      : t('not_available_for_purchase')
                  }`}
                </Typography>
              </div>
              <div className="mb-3 flex flex-column">
                <input
                  id="visible"
                  type="checkbox"
                  name="visible"
                  className="switch"
                  checked={values.visible}
                  onChange={handleChange}
                />
                <Typography className="text-base">
                  {' '}
                  {`${
                    values.visible
                      ? t('visible_to_consumer')
                      : t('hidden_to_consumer')
                  }`}
                </Typography>
              </div>
              <div className="my-4">
                <div className="font-semibold tracking-tight mb-1">
                  {t('min_purchase_quantity')}
                </div>
                <input
                  type="number"
                  name="minQuantity"
                  id="minQuantity"
                  min={1}
                  max={25}
                  className="text-gray-900 text-xs rounded p-2 w-1/4 appearance-none ring-0 focus:ring-0 border border-gray-200 focus:border-gray-300 outline-none focus:outline-none transition ease-in-out duration-500"
                  value={values.minQuantity}
                  onChange={handleChange}
                />
                {errors.minQuantity && touched.minQuantity && (
                  <div className="w-full font-semibold text-xs text-red-400 mt-1">
                    {errors.minQuantity}
                  </div>
                )}
              </div>
              <div className="my-4">
                <div className="font-semibold tracking-tight mb-1">
                  {t('max_purchase_quantity')}
                </div>
                <input
                  type="number"
                  name="maxQuantity"
                  id="maxQuantity"
                  min={1}
                  max={25}
                  className="text-gray-900 text-xs rounded p-2 w-1/4 appearance-none ring-0 focus:ring-0 border border-gray-200 focus:border-gray-300 outline-none focus:outline-none transition ease-in-out duration-500"
                  value={values.maxQuantity}
                  onChange={handleChange}
                />
                {errors.maxQuantity && touched.maxQuantity && (
                  <div className="w-full font-semibold text-xs text-red-400 mt-1">
                    {errors.maxQuantity}
                  </div>
                )}
              </div>
              <div className="my-4">
                <div className="font-semibold font-md tracking-tight mb-1">
                  {t('item_type')}
                </div>
                {categories && (
                  <select
                    id="type"
                    name="type"
                    value={values.type}
                    onChange={handleChange}
                  >
                    <option
                      key={0}
                      value={0}
                      className="text-sm flex items-center"
                    >
                      {t('in_store')}
                    </option>
                    <option
                      key={1}
                      value={1}
                      className="text-sm flex items-center"
                    >
                      {t('external_supplier')}
                    </option>
                  </select>
                )}
                {errors.sectionId && touched.sectionId && (
                  <div className="w-full font-semibold text-xs text-red-400 mt-1">
                    {errors.sectionId}
                  </div>
                )}
              </div>
              <div className="flex space-x-3 mt-10">
                <button
                  className="w-full md:w-1/2 text-xs transition delay-150 duration-300 ease-in-out border border-gray-700 hover:border-red-600 text-gray-500 hover:text-red-400 font-semibold px-6 py-3 rounded-full"
                  type="button"
                  size="small"
                  onClick={(e) => {
                    e.preventDefault();
                    setProduct(null);
                  }}
                >
                  {t('cancel')}
                </button>
                <button
                  size="small"
                  className="w-full md:w-1/2 flex items-center justify-center text-center text-xs transition delay-150 duration-300 ease-in-out bg-blue-500 hover:bg-blue-600 text-white font-semibold px-6 py-3 rounded-full"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {' '}
                  {isSubmitting && (
                    <div>
                      <svg
                        className="animate-spin -ml-1 mr-3 h-4 w-4 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        />
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        />
                      </svg>
                    </div>
                  )}
                  {t('save')}
                </button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    );
  }

  function prepareFlagsForm() {
    if (product == null) {
      return <div>No Data</div>;
    }
    return (
      <div>
        <Formik
          initialValues={{
            unitPriceFactor: product.unitPriceFactor,
            isAllNatural: product.natural,
            isLactoseFree: product.lactoseFree,
            isSugarFree: product.sugarFree,
            isKeto: product.keto,
            isOrganic: product.organic,
            isVegan: product.vegan,
            isVegetarian: product.vegetarian,
            isGlutenFree: product.glutenFree,
            isGrassFed: product.grassFed,
            isAntioxidant: product.antioxidant,
            isNutFree: product.nutFree,
            isNonGmo: product.nonGmo,
            hasRandomWeight: product.hasRandomWeight,
            madeLocally: product.madeInPR,
            hourDelay: product.timeConstraint?.hourDelay,
            minuteDelay: product.timeConstraint?.minuteDelay,
            availableOnMondays: product.timeConstraint?.availableOnMondays,
            availableOnTuesdays: product.timeConstraint?.availableOnTuesdays,
            availableOnWednesdays: product.timeConstraint?.availableOnWednesdays,
            availableOnThursdays: product.timeConstraint?.availableOnThursdays,
            availableOnFridays: product.timeConstraint?.availableOnFridays,
            availableOnSaturdays: product.timeConstraint?.availableOnSaturdays,
            availableOnSundays: product.timeConstraint?.availableOnSundays,
            limitDate: product.timeConstraint?.limitDate
          }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            api
              .post(
                `businesses/catalog/business/${product.key}/update`,
                {
                  Aisle: values.aisle ? values.aisle : product.aisle,
                  ImageUrl: product.imageUrl,
                  IsAvailable: product.available,
                  IsVisible: product.visible,
                  SectionId: product.sectionId,
                  Data: {
                    MinQuantity: values.minQuantity,
                    MaxQuantity: values.maxQuantity,
                    IsAllNatural: values.isAllNatural,
                    IsLactoseFree: values.isLactoseFree,
                    IsSugarFree: values.isSugarFree,
                    IsKeto: values.isKeto,
                    IsOrganic: values.isOrganic,
                    IsVegan: values.isVegan,
                    IsVegetarian: values.isVegetarian,
                    IsGlutenFree: values.isGlutenFree,
                    IsGrassFed: values.isGrassFed,
                    IsAntioxidant: values.isAntioxidant,
                    IsNutFree: values.isNutFree,
                    IsNonGmo: values.isNonGmo,
                    HasRandomWeight: values.hasRandomWeight,
                    MadeLocally: values.madeLocally,
                    UnitPriceFactor: values.unitPriceFactor,
                  },
                  TimeConstraint: {
                    LimitDate: values.limitDate,
                    HourDelay: values.hourDelay,
                    MinuteDelay: values.minuteDelay,
                    AvailableOnMondays: values.availableOnMondays,
                    AvailableOnTuesdays: values.availableOnTuesdays,
                    AvailableOnWednesdays: values.availableOnWednesdays,
                    AvailableOnThursdays: values.availableOnThursdays,
                    AvailableOnFridays: values.availableOnFridays,
                    AvailableOnSaturdays: values.availableOnSaturdays,
                    AvailableOnSundays: values.availableOnSundays,
                  }
                }
              )
              .then((response) => {
                if (response && response.data.error) {
                  notification.open({
                    message: 'Alert!',
                    description: (
                      <Comment
                        author={<span>Grocefy</span>}
                        avatar={<Avatar src={Grocefy} alt="grocefy" />}
                        content={
                          <p className="text-sm">{response.data.error}</p>
                        }
                      />
                    ),
                  });
                } else if (
                  response &&
                  response.data.success &&
                  response.data.message
                ) {
                  notification.open({
                    message: 'Success!',
                    description: (
                      <Comment
                        author={<span>Grocefy</span>}
                        avatar={<Avatar src={Grocefy} alt="grocefy" />}
                        content={
                          <p className="text-sm">{response.data.message}</p>
                        }
                      />
                    ),
                  });
                }
                setSubmitting(false);
                setProduct(null);
                setSearchTermAtom(product.upc);
                setReload(!reload);
              })
              .catch((error) => {
                console.error(error);
                setSubmitting(false);
              });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
          }) => (
            <form noValidate onSubmit={handleSubmit} className="text-md">
              <div className="m-2 text-lg font-semibold">{t('time_constraint')}</div>
              <Row>
                <Col span={24}>
                  <div className="mb-2">
                    <div className="font-semibold tracking-tight mb-1">
                      {t('limit_date')}
                    </div>
                    <input
                      type="date"
                      name="limitDate"
                      id="limitDate"
                      placeholder="01/01/2025"
                      className="text-gray-900 text-xs rounded p-4 w-full appearance-none
                      ring-0 focus:ring-0 border border-gray-200 focus:border-gray-300 outline-none
                       focus:outline-none transition ease-in-out duration-500"
                      value={values.limitDate}
                      onChange={handleChange}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={10}>
                  <div className="mb-2">
                    <div className="font-semibold tracking-tight mb-1">
                      {t('hour_delay')}
                    </div>
                    <input
                      type="decimal"
                      name="hourDelay"
                      id="hourDelay"
                      className="text-gray-900 text-md rounded p-2 w-3/4 appearance-none ring-0 focus:ring-0 border border-gray-200 focus:border-gray-300 outline-none focus:outline-none transition ease-in-out duration-500"
                      value={values.hourDelay}
                      onChange={handleChange}
                    />
                  </div>
                </Col>
                <Col span={8} offset={2}>
                  <div className="mb-2">
                    <div className="font-semibold tracking-tight mb-1">
                      {t('minute_delay')}
                    </div>
                    <input
                      type="decimal"
                      name="minuteDelay"
                      id="minuteDelay"
                      className="text-gray-900 text-md rounded p-2 w-3/4 appearance-none ring-0 focus:ring-0 border border-gray-200 focus:border-gray-300 outline-none focus:outline-none transition ease-in-out duration-500"
                      value={values.minuteDelay}
                      onChange={handleChange}
                    />
                  </div>
                </Col>
              </Row>
              <br />
              <Row>
                <Col span={10}>
                  <div className="mb-3 flex flex-column">
                    <input
                      id="availableOnMondays"
                      type="checkbox"
                      name="availableOnMondays"
                      className="switch"
                      size="small"
                      checked={values.availableOnMondays}
                      onChange={handleChange}
                    />
                    <Typography>
                      {t('available_on_mondays')}
                      {values.availableOnMondays}
                    </Typography>
                  </div>
                </Col>
                <Col span={8} offset={2}>
                  <div className="mb-3 flex flex-column">
                    <input
                      id="availableOnTuesdays"
                      type="checkbox"
                      name="availableOnTuesdays"
                      className="switch"
                      checked={values.availableOnTuesdays}
                      onChange={handleChange}
                    />
                    <Typography>
                      {t('available_on_tuesdays')}
                      {values.availableOnTuesdays}
                    </Typography>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={10}>
                  <div className="mb-3 flex flex-column">
                    <input
                      id="availableOnWednesdays"
                      type="checkbox"
                      name="availableOnWednesdays"
                      className="switch"
                      size="small"
                      checked={values.availableOnWednesdays}
                      onChange={handleChange}
                    />
                    <Typography>
                      {t('available_on_wednesdays')}
                      {values.availableOnWednesdays}
                    </Typography>
                  </div>
                </Col>
                <Col span={8} offset={2}>
                  <div className="mb-3 flex flex-column">
                    <input
                      id="availableOnThursdays"
                      type="checkbox"
                      name="availableOnThursdays"
                      className="switch"
                      checked={values.availableOnThursdays}
                      onChange={handleChange}
                    />
                    <Typography>
                      {t('available_on_thursdays')}
                      {values.availableOnThursdays}
                    </Typography>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={10}>
                  <div className="mb-3 flex flex-column">
                    <input
                      id="availableOnFridays"
                      type="checkbox"
                      name="availableOnFridays"
                      className="switch"
                      size="small"
                      checked={values.availableOnFridays}
                      onChange={handleChange}
                    />
                    <Typography>
                      {t('available_on_fridays')}
                      {values.availableOnFridays}
                    </Typography>
                  </div>
                </Col>
                <Col span={8} offset={2}>
                  <div className="mb-3 flex flex-column">
                    <input
                      id="availableOnSaturdays"
                      type="checkbox"
                      name="availableOnSaturdays"
                      className="switch"
                      checked={values.availableOnSaturdays}
                      onChange={handleChange}
                    />
                    <Typography>
                      {t('available_on_saturdays')}
                      {values.availableOnSaturdays}
                    </Typography>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <div className="mb-3 flex flex-column">
                    <input
                      id="availableOnSundays"
                      type="checkbox"
                      name="availableOnSundays"
                      className="switch"
                      size="small"
                      checked={values.availableOnSundays}
                      onChange={handleChange}
                    />
                    <Typography>
                      {t('available_on_sundays')}
                      {values.availableOnSundays}
                    </Typography>
                  </div>
                </Col>
              </Row>
              <Divider />

              <div>
                <div className="m-2 text-lg font-semibold">Health</div>
                <Row>
                  <Col span={10}>
                    <div className="mb-3 flex flex-column">
                      <input
                        id="natural"
                        type="checkbox"
                        name="isAllNatural"
                        className="switch"
                        size="small"
                        checked={values.isAllNatural}
                        onChange={handleChange}
                      />
                      <Typography>
                        {t('natural')}
                        {values.isAllNatural}
                      </Typography>
                    </div>
                  </Col>
                  <Col span={8} offset={2}>
                    <div className="mb-3 flex flex-column">
                      <input
                        id="lactose"
                        type="checkbox"
                        name="isLactoseFree"
                        className="switch"
                        checked={values.isLactoseFree}
                        onChange={handleChange}
                      />
                      <Typography>
                        {t('lactose_free')}
                        {values.isLactoseFree}
                      </Typography>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={10}>
                    <div className="mb-3 flex flex-column">
                      <input
                        id="sugar"
                        type="checkbox"
                        name="isSugarFree"
                        className="switch"
                        size="small"
                        checked={values.isSugarFree}
                        onChange={handleChange}
                      />
                      <Typography>
                        {t('sugar_free')}
                        {values.isSugarFree}
                      </Typography>
                    </div>
                  </Col>
                  <Col span={8} offset={2}>
                    <div className="mb-3 flex flex-column">
                      <input
                        id="keto"
                        type="checkbox"
                        name="isKeto"
                        className="switch"
                        checked={values.isKeto}
                        onChange={handleChange}
                      />
                      <Typography>
                        Keto
                        {values.isKeto}
                      </Typography>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={10}>
                    <div className="mb-3 flex flex-column">
                      <input
                        id="organic"
                        type="checkbox"
                        name="isOrganic"
                        className="switch"
                        size="small"
                        checked={values.isOrganic}
                        onChange={handleChange}
                      />
                      <Typography>
                        {t('organic')}
                        {values.isOrganic}
                      </Typography>
                    </div>
                  </Col>
                  <Col span={8} offset={2}>
                    <div className="mb-3 flex flex-column">
                      <input
                        id="vegan"
                        type="checkbox"
                        name="isVegan"
                        className="switch"
                        checked={values.isVegan}
                        onChange={handleChange}
                      />
                      <Typography>
                        {t('vegan')}
                        {values.isVegan}
                      </Typography>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={10}>
                    <div className="mb-3 flex flex-column">
                      <input
                        id="vegetartian"
                        type="checkbox"
                        name="isVegetarian"
                        className="switch"
                        size="small"
                        checked={values.isVegetarian}
                        onChange={handleChange}
                      />
                      <Typography>
                        {t('vegetarian')}
                        {values.isVegetarian}
                      </Typography>
                    </div>
                  </Col>
                  <Col span={8} offset={2}>
                    <div className="mb-3 flex flex-column">
                      <input
                        id="gluteb"
                        type="checkbox"
                        name="isGlutenFree"
                        className="switch"
                        checked={values.isGlutenFree}
                        onChange={handleChange}
                      />
                      <Typography>
                        {t('gluten_free')}
                        {values.isGlutenFree}
                      </Typography>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={10}>
                    <div className="mb-3 flex flex-column">
                      <input
                        id="grassFed"
                        type="checkbox"
                        name="isGrassFed"
                        className="switch"
                        size="small"
                        checked={values.isGrassFed}
                        onChange={handleChange}
                      />
                      <Typography>
                        {t('grass_fed')}
                        {values.isGrassFed}
                      </Typography>
                    </div>
                  </Col>
                  <Col span={8} offset={2}>
                    <div className="mb-3 flex flex-column">
                      <input
                        id="antioxidant"
                        type="checkbox"
                        name="isAntioxidant"
                        className="switch"
                        checked={values.isAntioxidant}
                        onChange={handleChange}
                      />
                      <Typography>
                        {t('antioxidant')}
                        {values.isAntioxidant}
                      </Typography>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={10}>
                    <div className="mb-3 flex flex-column">
                      <input
                        id="nutFree"
                        type="checkbox"
                        name="isNutFree"
                        className="switch"
                        size="small"
                        checked={values.isNutFree}
                        onChange={handleChange}
                      />
                      <Typography>
                        {t('nut_free')}
                        {values.isNutFree}
                      </Typography>
                    </div>
                  </Col>
                  <Col span={8} offset={2}>
                    <div className="mb-3 flex flex-column">
                      <input
                        id="nonGmo"
                        type="checkbox"
                        name="isNonGmo"
                        className="switch"
                        checked={values.isNonGmo}
                        onChange={handleChange}
                      />
                      <Typography>
                        Non GMO
                        {values.isNonGmo}
                      </Typography>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={10}>
                    <div className="mb-3 flex flex-column">
                      <input
                        id="madeLocally"
                        type="checkbox"
                        name="madeLocally"
                        className="switch"
                        checked={values.madeLocally}
                        onChange={handleChange}
                      />
                      <Typography>
                        {t('made_locally')}
                        {values.madeLocally}
                      </Typography>
                    </div>
                  </Col>
                  <Col span={8} offset={2}>
                    <div className="mb-3 flex flex-column" />
                  </Col>
                </Row>
              </div>

              <Divider />
              <div className="m-2 text-lg font-semibold">
                {t('sold_by_weight')}
              </div>
              <Row>
                {' '}
                <Col span={12}>
                  <div className="mb-3 flex flex-column mt-6">
                    <input
                      id="hasRandomWeight"
                      type="checkbox"
                      name="hasRandomWeight"
                      className="switch "
                      size="small"
                      checked={values.hasRandomWeight}
                      onChange={handleChange}
                    />
                    <Typography>
                      {t('random_weight')}
                      {values.hasRandomWeight}
                    </Typography>
                  </div>
                </Col>
                <Col span={6} offset={2}>
                  <div className="mb-2">
                    <div className="font-semibold tracking-tight mb-1">
                      {t('unit_price_factor')}
                    </div>
                    <input
                      type="decimal"
                      name="unitPriceFactor"
                      id="unitPriceFactor"
                      className="text-gray-900 text-md rounded p-2 w-3/4 appearance-none ring-0 focus:ring-0 border border-gray-200 focus:border-gray-300 outline-none focus:outline-none transition ease-in-out duration-500"
                      value={values.unitPriceFactor}
                      onChange={handleChange}
                    />
                  </div>
                </Col>
              </Row>
              <Divider />
              <div className="m-2 text-lg font-semibold">Other</div>
              <Row>
                <Col span={24}>
                  <div className="mb-3 flex flex-column">
                    <input
                      id="Alcoholic Beverage"
                      type="checkbox"
                      name="isAlcoholivBeverage"
                      className="switch"
                      size="small"
                      checked={values.isAlcoholivBeverage}
                      onChange={handleChange}
                    />
                    <Typography>
                      {t('alcoholic_beverage')}
                      {values.isAlcoholivBeverage}
                    </Typography>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <div className="mb-3 flex flex-column">
                    <input
                      id="idRequired"
                      type="checkbox"
                      name="isLactoseFree"
                      className="switch"
                      checked={values.idRequired}
                      onChange={handleChange}
                    />
                    <Typography>
                      {t('id_required')}
                      {values.idRequired}
                    </Typography>
                  </div>
                </Col>
              </Row>
              <div className="flex space-x-3 mt-10">
                <button
                  className="w-full md:w-1/2 text-xs transition delay-150 duration-300 ease-in-out border border-gray-700 hover:border-red-600 text-gray-500 hover:text-red-400 font-semibold px-6 py-3 rounded-full"
                  type="button"
                  size="small"
                  onClick={(e) => {
                    e.preventDefault();
                    setProduct(null);
                  }}
                >
                  {t('cancel')}
                </button>
                <button
                  size="small"
                  className="w-full md:w-1/2 flex items-center justify-center text-center text-xs transition delay-150 duration-300 ease-in-out bg-blue-500 hover:bg-blue-600 text-white font-semibold px-6 py-3 rounded-full"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {' '}
                  {isSubmitting && (
                    <div>
                      <svg
                        className="animate-spin -ml-1 mr-3 h-4 w-4 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        />
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        />
                      </svg>
                    </div>
                  )}
                  {t('save')}
                </button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    );
  }

  function preparePricingForm() {
    if (product == null) {
      return <div>No Data</div>;
    }
    return (
      <div>
        <Formik
          initialValues={{
            taxRule: product.taxRule,
            baseUnitPrice: product.baseUnitPrice,
            baseUnitPriceMethod: product.baseUnitPriceMethod,
            baseUnitMultiple: product.baseUnitMultiple,
            promoUnitPrice: product.promoUnitPrice,
            promoUnitPriceMethod: product.promoUnitPriceMethod,
            promoUnitMultiple: product.promoUnitMultiple,
            promoStartDate: product.promoStartDate,
            promoEndDate: product.promoEndDate,
            munTaxPct: product.munTaxPct,
            stateTaxPct: product.stateTaxPct,

            cost: product.cost,
            pack: product.pack,
          }}
          validate={(values) => {
            const errors = {};
            if (values.baseUnitPrice < 0) {
              errors.baseUnitPrice = 'Price must be a decimal greater than 0.';
            }
            if (values.baseUnitMultiple < 0) {
              errors.baseUnitMultiple =
                'Multiple must be an integer greater than 0.';
            }
            if (values.pack < 0) {
              errors.pack = 'Pack must be an integer greater than 0.';
            }

            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            const temp = JSON.parse(JSON.stringify(dates));
            if (temp.promoStartDate && temp.promoStartDate.length > 0) {
              temp.promoStartDate = moment(temp.promoStartDate);
            }
            if (temp.promoStartDate && temp.promoStartDate.length > 0 && temp.promoStartDate.length < 17) {
              temp.promoStartDate = `${temp.promoStartDate.replace(' ', 'T')}:00.000`;
            }
            if (temp.promoEndDate && temp.promoEndDate.length > 0) {
              temp.promoEndDate = moment(temp.promoEndDate);
            }
            if (temp.promoEndDate && temp.promoEndDate.length > 0) {
              temp.promoEndDate = `${temp.promoEndDate.replace(' ', 'T')}:00.000`;
            }
            const json = {
              Aisle: values.aisle ? values.aisle : product.aisle,
              IsAvailable: product.available,
              IsVisible: product.visible,
              SectionId: product.sectionId,
              BlockOostrigger: product.blockOostrigger,
              OosLimit: product.oosLimit,
              BlockPromoPricing: product.BlockPromoPricing,
              SponsorshipStartDate: product.sponsorshipStartDate,
              SponsorshipEndDate: product.sponsorshipEndDate,
              Department: product.department,
              IsDeleted: false,
              Margin: product.nargin,
              IsPromoted: product.isPromoted,
              MunTaxPct: values.munTaxPct / 100,
              StateTaxPct: values.stateTaxPct / 100,
              CustomBooleanProperty01: product.customBooleanProperty01,
              CustomBooleanProperty02: product.customBooleanProperty02,
              CustomBooleanProperty03: product.customBooleanProperty03,
              CustomBooleanProperty04: product.customBooleanProperty04,
              CustomBooleanProperty05: product.customBooleanProperty05,
              CustomBooleanProperty06: product.customBooleanProperty06,

              Price: {
                BaseMultiple: values.baseUnitMultiple,
                BasePrice: values.baseUnitPrice,
                BasePriceMethod: values.baseUnitPriceMethod,
                Cost: values.cost,
                Pack: values.pack,
                PromoStartDate: temp.promoStartDate,
                PromoEndDate: temp.promoEndDate,
                PromoMultiple: product.promoUnitMultiple,
                PromoPrice: values.promoUnitPrice,
                PromoPriceMethod: product.promoUnitPriceMethod,
                TaxRule: values.taxRule,
              },
            };
            api
              .post(
                `businesses/catalog/business/${product.key}/update`,
                json
              )
              .then((response) => {
                if (response && response.data.error) {
                  notification.open({
                    message: 'Alert!',
                    description: (
                      <Comment
                        author={<span>Grocefy</span>}
                        avatar={<Avatar src={Grocefy} alt="grocefy" />}
                        content={
                          <p className="text-sm">{response.data.error}</p>
                        }
                      />
                    ),
                  });
                } else if (
                  response &&
                  response.data.success &&
                  response.data.message
                ) {
                  notification.open({
                    message: 'Success!',
                    description: (
                      <Comment
                        author={<span>Grocefy</span>}
                        avatar={<Avatar src={Grocefy} alt="grocefy" />}
                        content={
                          <p className="text-sm">{response.data.message}</p>
                        }
                      />
                    ),
                  });
                }
                setSubmitting(false);
                setProduct(null);
                setSearchTermAtom(product.upc);
                setReload(!reload);
              })
              .catch((error) => {
                console.error(error);
                setSubmitting(false);
              });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
          }) => (
            <form noValidate onSubmit={handleSubmit} className="text-md">
              <div className="mb-0">
                <div className="font-semibold tracking-tight text-lg mb-0">
                  Tax Rule
                </div>
                <Row>
                  <Col span={6} offset={0}>
                    <select
                      id="taxRule"
                      className="font-semibold mt-1"
                      name="taxRule"
                      value={values.taxRule}
                      onChange={handleChange}
                    >
                      <option key={0} value={0}>
                        {t('none')}
                      </option>
                      <option key={1} value={1}>
                        Municipal
                      </option>
                      <option key={2} value={2}>
                        State
                      </option>
                      <option key={3} value={3}>
                        {t('both')}
                      </option>
                    </select>
                  </Col>
                  <Col span={6} offset={2}>
                    <div className="mb-2">
                      <div className="font-semibold tracking-tight mb-1">
                        State Tax %
                      </div>
                      <input
                        type="decimal"
                        name="stateTaxPct"
                        id="stateTaxPct"
                        className="text-gray-900 text-md rounded p-2 w-3/4 appearance-none ring-0 focus:ring-0 border border-gray-200 focus:border-gray-300 outline-none focus:outline-none transition ease-in-out duration-500"
                        value={values.stateTaxPct}
                        onChange={handleChange}
                      />
                    </div>
                  </Col>
                  <Col span={6} offset={2}>
                    <div className="mb-2">
                      <div className="font-semibold tracking-tight mb-1">
                        Mun Tax %
                      </div>
                      <input
                        type="decimal"
                        name="munTaxPct"
                        id="munTaxPct"
                        className="text-gray-900 text-md rounded p-2 w-3/4 appearance-none ring-0 focus:ring-0 border border-gray-200 focus:border-gray-300 outline-none focus:outline-none transition ease-in-out duration-500"
                        value={values.munTaxPct}
                        onChange={handleChange}
                      />
                    </div>
                  </Col>
                </Row>
              </div>

              <Divider />
              <div className="font-semibold text-lg tracking-tight mb-1">
                {t('regular_price')}
              </div>
              <Row>
                <Col span={4} offset={0}>
                  <div className="mb-0">
                    <div className="font-semibold tracking-tight mb-0">
                      {t('base_price')}
                    </div>
                    <input
                      type="decimal"
                      name="baseUnitPrice"
                      id="baseUnitPrice"
                      className="text-gray-900 text-md mt-2 rounded p-2 w-full appearance-none ring-0 focus:ring-0 border border-gray-200 focus:border-gray-300 outline-none focus:outline-none transition ease-in-out duration-500"
                      value={values.baseUnitPrice}
                      onChange={handleChange}
                    />
                    {errors.baseUnitPrice && touched.baseUnitPrice && (
                      <div className="w-full font-semibold text-xs text-red-400 mt-1">
                        {errors.baseUnitPrice}
                      </div>
                    )}
                  </div>
                </Col>{' '}
                <Col span={8} offset={2}>
                  <div className="mb-0">
                    <div className="font-semibold tracking-tight mb-0">
                      {t('price_method')}
                    </div>
                    <select
                      className="mt-2 border border-gray-200 p-2"
                      id="baseUnitPriceMethod"
                      name="baseUnitPriceMethod"
                      value={values.baseUnitPriceMethod}
                      onChange={handleChange}
                    >
                      <option key={0} value={0}>
                        {t('unit')}
                      </option>
                      <option key={1} value={1}>
                        {t('split')}
                      </option>
                    </select>
                  </div>
                </Col>
                <Col span={4} offset={2}>
                  <div className="mb-2">
                    <div className="font-semibold tracking-tight mb-1">
                      Multiple
                    </div>
                    <input
                      type="integer"
                      name="baseMultiple"
                      id="baseMultiple"
                      className="text-gray-900 text-md rounded p-2 w-3/4 appearance-none ring-0 focus:ring-0 border border-gray-200 focus:border-gray-300 outline-none focus:outline-none transition ease-in-out duration-500"
                      value={values.baseUnitMultiple}
                      onChange={handleChange}
                    />
                  </div>
                </Col>
                <Col span={4} offset={2}>
                  <div className="mb-2">
                    <div className="font-semibold tracking-tight mb-1">
                      Cost
                    </div>
                    <input
                      type="decimal"
                      name="cost"
                      id="cost"
                      className="text-gray-900 text-md rounded p-2 w-3/4 appearance-none ring-0 focus:ring-0 border border-gray-200 focus:border-gray-300 outline-none focus:outline-none transition ease-in-out duration-500"
                      value={values.cost}
                      onChange={handleChange}
                    />
                  </div>
                </Col>
              </Row>

              <Divider />
              <div className="font-semibold text-lg tracking-tight mb-1">
                {t('promo_price')}
              </div>
              <Row>
                <Col span={4} offset={0}>
                  <div className="mb-0">
                    <div className="font-semibold tracking-tight mb-0">
                      {t('price')}
                    </div>
                    <input
                      type="decimal"
                      name="promoUnitPrice"
                      id="promoUnitPrice"
                      className="text-gray-900 text-md mt-2 rounded p-2 w-full appearance-none ring-0 focus:ring-0 border border-gray-200 focus:border-gray-300 outline-none focus:outline-none transition ease-in-out duration-500"
                      value={values.promoUnitPrice}
                      onChange={handleChange}
                    />
                    {errors.promoUnitPrice && touched.promoUnitPrice && (
                      <div className="w-full font-semibold text-xs text-red-400 mt-1">
                        {errors.promoUnitPrice}
                      </div>
                    )}
                  </div>
                </Col>{' '}
                <Col span={8} offset={2}>
                  <div className="mb-0">
                    <div className="font-semibold tracking-tight mb-0">
                      {t('price_method')}
                    </div>
                    <select
                      className="mt-2 border border-gray-200 p-2"
                      id="promoUnitPriceMethod"
                      name="promoUnitPriceMethod"
                      value={values.promoUnitPriceMethod}
                      onChange={handleChange}
                    >
                      <option key={0} value={0}>
                        {t('unit')}
                      </option>
                      <option key={1} value={1}>
                        {t('split')}
                      </option>
                    </select>
                  </div>
                </Col>
                <Col span={4} offset={2}>
                  <div className="mb-2">
                    <div className="font-semibold tracking-tight mb-1">
                      Multiple
                    </div>
                    <input
                      type="integer"
                      name="promoUnitMultiple"
                      id="promoUnitMultiple"
                      className="text-gray-900 text-md rounded p-2 w-3/4 appearance-none ring-0 focus:ring-0 border border-gray-200 focus:border-gray-300 outline-none focus:outline-none transition ease-in-out duration-500"
                      value={values.promoUnitMultiple}
                      onChange={handleChange}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                {' '}
                <div className="my-4">
                  <div className="font-semibold tracking-tight mb-1">{t('cost')}</div>
                  <input
                    type="decimal"
                    name="cost"
                    id="cost"
                    className="text-gray-900 disabled text-md rounded p-2 w-3/4 appearance-none ring-0 focus:ring-0 border border-gray-200 focus:border-gray-300 outline-none focus:outline-none transition ease-in-out duration-500"
                    value={values.cost}
                    onChange={handleChange}
                  />
                </div>
                <br />
                <div className="my-4">
                  <div className="font-semibold tracking-tight mb-1">{t('pack')}</div>
                  <input
                    type="decimal"
                    name="pack"
                    id="pack"
                    className="text-gray-900 text-md rounded p-2 w-3/4 appearance-none ring-0 focus:ring-0 border border-gray-200 focus:border-gray-300 outline-none focus:outline-none transition ease-in-out duration-500"
                    value={values.pack}
                    onChange={handleChange}
                  />
                </div>
                <br />
                <div style={{ marginBottom: 10 }}>
                  <strong>{t('start_date')} - {t('end_date')}</strong> {' '}
                  <RangePicker
                    showTime={{ format: 'HH:mm' }}
                    format="YYYY-MM-DD HH:mm"
                    defaultValue={[
                      dayjs(dates.promoStartDate, 'YYYY-MM-DD HH:mm'),
                      dayjs(dates.promoEndDate, 'YYYY-MM-DD HH:mm')
                    ]}
                    onChange={(date, dateString) => {
                      const temp = JSON.parse(JSON.stringify(dates));
                      const start = dateString[0];
                      const end = dateString[1];
                      temp.promoStartDate = start;
                      temp.promoEndDate = end;
                      setDates(temp);
                    }}
                  />
                </div>
              </Row>
              <Divider />

              <div className="flex space-x-3 mt-10">
                <button
                  className="w-full md:w-1/2 text-xs transition delay-150 duration-300 ease-in-out border border-gray-700 hover:border-red-600 text-gray-500 hover:text-red-400 font-semibold px-6 py-3 rounded-full"
                  type="button"
                  size="small"
                  onClick={(e) => {
                    e.preventDefault();
                    setProduct(null);
                  }}
                >
                  {t('cancel')}
                </button>
                <button
                  size="small"
                  className="w-full md:w-1/2 flex items-center justify-center text-center text-xs transition delay-150 duration-300 ease-in-out bg-blue-500 hover:bg-blue-600 text-white font-semibold px-6 py-3 rounded-full"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {' '}
                  {isSubmitting && (
                    <div>
                      <svg
                        className="animate-spin -ml-1 mr-3 h-4 w-4 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        />
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        />
                      </svg>
                    </div>
                  )}
                  {t('save')}
                </button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    );
  }

  return (
    <Drawer
      title={t('edit_product')}
      placement="right"
      closable={false}
      onClose={() => setProduct(null)}
      open={!!product}
      width={450}
    >
      <div>
        <div className="mb-3">
          <div className="tracking-tight mb-2 font-semibold text-lg">
            {product && product.completeName}
          </div>
          <Row>
            <Col span={10} offset={0}>
              <div className="tracking-tight mb-2">
                ID:{' '}
                <Typography.Text className="ml-2 font-normal" copyable>
                  {product && product.gtin}
                </Typography.Text>
              </div>
            </Col>
            <Col span={10} offset={2}>
              <div className="tracking-tight">
                UPC:{' '}
                <Typography.Text className="ml-2 font-normal" copyable>
                  {product && product.upc}
                </Typography.Text>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="General" key="1">
          <div>{prepareForm()}</div>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Flags" key="2">
          <div>{prepareFlagsForm()}</div>
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('pricing')} key="3">
          <div>{preparePricingForm()}</div>
        </Tabs.TabPane>
      </Tabs>
    </Drawer>
  );
}

export default EditProduct;
