const envConfig = {
  REACT_APP_API_TOKEN: process.env.REACT_APP_API_TOKEN ?? 'a8c4f29c3a8c48cc86c168844bcb8dc5',
  REACT_APP_API_URL: process.env.REACT_APP_API_URL ?? 'https://lhf-prod-api.azurewebsites.net/api/',
  REACT_APP_CLIENT_ID: process.env.REACT_APP_CLIENT_ID ?? '619d8bc9-fbe4-4164-90e4-391768ec670d',
  REACT_APP_HUB_URL: process.env.REACT_APP_HUB_URL ?? 'https://lhf-prod-api.azurewebsites.net/',
  REACT_APP_GEO_API_KEY: process.env.REACT_APP_GEO_API_KEY ?? 'AIzaSyB6jl09IPMyXwc55wjIj40Mi9p2ABtZKf0',
  REACT_APP_PREFIX: process.env.REACT_APP_PREFIX ?? 'grocefy-lhf',

  REACT_APP_DEFAULT_LANGUAGE: process.env.REACT_APP_DEFAULT_LANGUAGE ?? 'en',

  REACT_APP_SERVER_CONFIG: process.env.REACT_APP_SERVER_CONFIG ?? 'Endpoint=https://api-prod-config.azconfig.io;Id=+jFh-l0-s0:YJ5oBDrT7V/dCchPblRL;Secret=jCeD03/qF88ZgMZwXkIkYMIMA/5ZroXWkN0Cvh+FRI4=',
  REACT_APP_APP_CONFIG: process.env.REACT_APP_APP_CONFIG ?? 'Endpoint=https://app-dev-config.azconfig.io;Id=frnP-l6-s0:5KLrWwgUllMu75X/BBC/;Secret=DctyjavQeWRg/NfkmmI2W8nNSKPR/qfkF7MvdFDhHXA=',
  REACT_APP_MANAGER_CONFIG: process.env.REACT_APP_MANAGER_CONFIG ?? 'Endpoint=https://lhf-manager-config.azconfig.io;Id=DJfI;Secret=PTsimPlVWTqd8wIr1/FppKNSLg8TDJriXzsYbRR0vVU=',

  REACT_APP_IMAGES_URL: process.env.REACT_APP_IMAGES_URL ?? '#{REACT_APP_IMAGES_URL}#',
  REACT_APP_IMAGES_KEY: process.env.REACT_APP_IMAGES_KEY ?? '#{REACT_APP_IMAGES_KEY}#',
  REACT_APP_UTILITY_KEY: process.env.REACT_APP_UTILITY_KEY ?? 'utility-b60bbed139054e2289dfa5d29e4c63aab495f236d3af48168378c41dc671d643',
  REACT_APP_PAYMENTS_KEY: process.env.REACT_APP_PAYMENTS_KEY ?? '9f09ee1311b041afad4a09b53bce41022e952f1576be4933b63a3af9e1860745bf6642961a6e45aba8f05363ca875473c6a4b854e1ab40e19c79b033b3f5a17e',
  REACT_APP_BOOKING_API_URL: process.env.REACT_APP_BOOKING_API_URL ?? '#{REACT_APP_BOOKING_API_URL}#',
  REACT_APP_BOOKING_API_KEY: process.env.REACT_APP_BOOKING_API_KEY ?? '#{REACT_APP_BOOKING_API_KEY}#',
  REACT_APP_TIMEZONE_OFFSET: process.env.REACT_APP_TIMEZONE_OFFSET ?? '-4',
  REACT_APP_BOOKING_CANCELLATION_FEE: process.env.REACT_APP_BOOKING_CANCELLATION_FEE ?? '0',
  BookingEventUrl: process.env.REACT_APP_BOOKING_API_EVENT_URL ?? '#{REACT_APP_BOOKING_API_EVENT_URL}#',
  SpecificProductUrl: process.env.REACT_APP_SPECIFIC_PRODUCT_URL ?? '#{REACT_APP_SPECIFIC_PRODUCT_URL}#',
  WebsiteUrl: process.env.REACT_APP_WEBSITE_URL ?? '#{REACT_APP_WEBSITE_URL}#'

};

export default envConfig;
